import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import { Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';

const Tag = styled(Link)`
  display: inline-block;
  padding: 4px 15px;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.05);
  border-radius: 3px;
  text-decoration: none;

  transition: all 300ms;

  :hover {
    background: rgba(0, 0, 0, 0.08);
  }
`;

const TagLink = ({ tag }) => <Tag to={`/tags/${kebabCase(tag)}`}>{tag}</Tag>;

TagLink.propTypes = {
  tag: PropTypes.string.isRequired,
};

export default TagLink;
